<template>
  <div>
    <Loading v-if="isLoading" loading-message="載入中..." :background-color="'#ffffff'"/>

    <img
      class="banner-image w-100"
      v-if="deepGet(form, 'config.thanks_page.banner_image')"
      :src="deepGet(form, 'config.thanks_page.banner_image')"
    />

    <div class="page">
      <div
        v-if="deepGet(form, 'config.thanks_page.form_title')"
        class="page__title"
      >
        {{ deepGet(form, 'config.thanks_page.form_title') }}
      </div>
      <div v-if="deepGet(form, 'config.thanks_page.form_desc')" class="page__desc">
        {{ deepGet(form, 'config.thanks_page.form_desc') }}
      </div>
      <div
        v-if="deepGet(form, 'config.thanks_page.content')"
        v-html="deepGet(form, 'config.thanks_page.content')"
      ></div>
      <div class="page__button s-space-y-4">
        <MemberButton
          :button-text="deepGet(form, 'config.thanks_page.button_text')"
          :button-url="deepGet(form, 'config.thanks_page.button_url')"
          :type="deepGet(form, 'config.thanks_page.button_action')"
        >
        </MemberButton>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isAfter,
  set,
  getHours,
  getMinutes,
} from "date-fns";
import formApi from "@/apis/liff/v2/form";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import Loading from "@/components/Page/Liff/Shared/Loading";
import deepGet from "@/utils/deepGet";

export default {
  components: {
    Loading,
    MemberButton,
  },
  data() {
    return {
      form: {},
      isLoading: true,
    };
  },
  computed: {
    hasStarted() {
      const today = set(new Date(), {
        hours: String(getHours(new Date())).padStart(2, "0"),
        minutes: String(getMinutes(new Date())).padStart(2, "0"),
      });

      const startDate = set(new Date(this.form.start_at), {
        hours: String(getHours(new Date(this.form.start_at))).padStart(2, "0"),
        minutes: String(getMinutes(new Date(this.form.start_at))).padStart(2, "0"),
      });

      return isAfter(today, startDate);
    },
    hasEnded() {
      const today = set(new Date(), {
        hours: String(getHours(new Date())).padStart(2, "0"),
        minutes: String(getMinutes(new Date())).padStart(2, "0"),
      });

      const endDate = set(new Date(this.form.end_at), {
        hours: String(getHours(new Date(this.form.end_at))).padStart(2, "0"),
        minutes: String(getMinutes(new Date(this.form.end_at))).padStart(2, "0"),
      });

      return isAfter(today, endDate);
    },
    isInValidTimeRange() {
      return this.hasStarted && false == this.hasEnded;
    },
  },
  async mounted() {
    await this.fetchForm();

    if (false == this.isInValidTimeRange || this.form.status !== 'published') {
      this.$router.replace({ name: "LiffFormIndex", params: { form_id: this.form.id } });
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    deepGet,
    async fetchForm() {
      try {
        const response = await formApi.getForm(
          this.$route.params.form_id,
          {}
        );
        this.form = response.data.data;
      } catch (error) {
        if (error.response.status === 404) {
          this.$swal("錯誤", "找不到此問券", "error");
        } else {
          console.error(error);
          this.$swal("錯誤", "", "error");
        }
      }
    },
  },
};
</script>
