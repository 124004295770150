<template>
  <div>
    <Loading
      v-if="isLoading"
      loading-message="載入中..."
      :background-color="'#ffffff'"
    />

    <img
      class="banner-image w-100"
      v-if="deepGet(form, 'config.content_page.banner_image')"
      :src="deepGet(form, 'config.content_page.banner_image')"
    />

    <div class="page">
      <div
        v-if="deepGet(form, 'config.content_page.form_title')"
        class="page__title"
      >
        {{ deepGet(form, 'config.content_page.form_title') }}
      </div>
      <div v-if="deepGet(form, 'config.content_page.form_desc')" class="page__desc">
        {{ deepGet(form, 'config.content_page.form_desc') }}
      </div>
      <div
        v-if="deepGet(form, 'config.content_page.content')"
        v-html="deepGet(form, 'config.content_page.content')"
        class="page__content"
      ></div>

      <DynamicForm
        ref="dynamicForm"
        class="my-4 mx-2"
        :input-subjects="subjects"
        v-model="inputForm"
      ></DynamicForm>

      <SharedButton class="s-btn-bg-primary" @click="submitForm">
        {{ deepGet(form, 'config.content_page.button_text', '送出') }}
      </SharedButton>

      <div
        v-if="deepGet(form, 'terms')"
        v-html="deepGet(form, 'terms')"
        class="mt-3 p-3"
      ></div>
    </div>
  </div>
</template>

<script>
import {
  isAfter,
  set,
  getHours,
  getMinutes,
} from "date-fns";
import { mapState } from "vuex";
import formApi from "@/apis/liff/v2/form";
import DynamicForm from "@/components/DynamicForm/DynamicForm.vue";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import Loading from "@/components/Page/Liff/Shared/Loading";
import deepGet from "@/utils/deepGet";
import { parseFormSubjects } from "@/utils/DynamicFormUtils";

export default {
  components: {
    DynamicForm,
    SharedButton,
    Loading,
  },
  computed: {
    ...mapState({
      user: (state) => state.liffAuth.user,
    }),
    hasStarted() {
      const today = set(new Date(), {
        hours: String(getHours(new Date())).padStart(2, "0"),
        minutes: String(getMinutes(new Date())).padStart(2, "0"),
      });

      const startDate = set(new Date(this.form.start_at), {
        hours: String(getHours(new Date(this.form.start_at))).padStart(2, "0"),
        minutes: String(getMinutes(new Date(this.form.start_at))).padStart(2, "0"),
      });

      return isAfter(today, startDate);
    },
    hasEnded() {
      const today = set(new Date(), {
        hours: String(getHours(new Date())).padStart(2, "0"),
        minutes: String(getMinutes(new Date())).padStart(2, "0"),
      });

      const endDate = set(new Date(this.form.end_at), {
        hours: String(getHours(new Date(this.form.end_at))).padStart(2, "0"),
        minutes: String(getMinutes(new Date(this.form.end_at))).padStart(2, "0"),
      });

      return isAfter(today, endDate);
    },
    isInValidTimeRange() {
      return this.hasStarted && false == this.hasEnded;
    },
  },
  data() {
    return {
      inputForm: {},
      subjects: [],
      form: {},
      isLoading: true,
      startedWriting: false,
    };
  },
  watch: {
    inputForm: {
      handler: function (val) {
        console.log("form", val);
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    await this.fetchForm();

    if (false == this.isInValidTimeRange || this.form.status !== 'published') {
      this.$router.replace({ name: "LiffFormIndex", params: { form_id: this.form.id } });
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    deepGet,
    async fetchForm() {
      try {
        const response = await formApi.getForm(this.$route.params.form_id, {});
        this.form = response.data.data;
        const checkRegisteredPemission =
          this.form.config.permissions.includes("registered");
        if (checkRegisteredPemission && !this.user.registered_at) {
          this.$router.push({ name: "LiffRegisterIndex" });
        } else {
          this.subjects = parseFormSubjects(this.form);
        }
      } catch (error) {
        if (error.response.status === 404) {
          this.$swal("錯誤", "找不到此問券", "error");
        } else {
          console.error(error);
          this.$swal("錯誤", "", "error");
        }
      }
    },
    async submitForm() {
      const validate = await this.$refs.dynamicForm.validate();

      if (!validate) return;

      try {
        this.isLoading = true;

        let answers = [];

        for (let i = 0; i < this.form.subjects.length; i++) {
          const subject = this.form.subjects[i];
          let answer = {
            subject_id: subject.id,
            value: this.inputForm[subject.id] ?? null,
          };
          answers.push(answer);
        }

        await formApi.saveResult(this.form.id, { answers });

        this.$router.push({ name: "LiffFormThanks" });
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "送出問券時發生問題，請稍候再試", "error");
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.form-title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  margin-top: 12px;
  margin-bottom: 12px;
}
</style>
